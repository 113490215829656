// src/App.tsx

import React, { useState } from 'react';
import './App.css';
import ApiForm from './ApiForm';
import KeyForm from './KeyForm';

function App() {
  const [apiKey, setApiKey] = useState(localStorage.getItem('api_key'));
  // const apiKey = localStorage.getItem('api_key');
  return (
    <>
    <header>
        <a href="https://www.bernardteske.de" target="_blank" rel="noreferrer"><img src="https://www.bernardteske.de/bernardteske-wGlobal/wGlobal/layout/images/logo_btds_rgb.svg" alt="logo" className="logo"/></a>
    </header>
    <div className="App">
      <h1>KI-SEO-Snippet-Generator</h1>
      {apiKey ? <ApiForm removeKey={setApiKey} /> : <KeyForm callback={setApiKey} />}
    </div>
    <footer>
        <nav>
          <ul>
            <li><a href="https://www.bernardteske.de" target="_blank" rel="noreferrer">Zur Webseite</a></li>
            <li><a href="https://www.bernardteske.de/impressum/" target="_blank" rel="noreferrer">Impressum</a></li>
            <li><a href="https://www.bernardteske.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a></li>
          </ul>
        </nav>
    </footer>
    </>
  );
}

export default App;
