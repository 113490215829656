// src/ApiForm.tsx

import React, { useState } from 'react';

interface KeyFormProps {
  removeKey: (apiKey: string) => void;
}

const ApiForm: React.FC<KeyFormProps> = ({ removeKey }) => {
  const [url, setUrl] = useState('');
  const [data, setData] = useState<{ seoTitle?: string; seoDescription?: string }>({});
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    localStorage.setItem('api_key', ''); // Speichert den apiKey im LocalStorage
    removeKey('');
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // Erfolgreiches Kopieren
        console.log('Text kopiert:', text);
      })
      .catch(err => {
        // Fehler beim Kopieren
        console.error('Fehler beim Kopieren:', err);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!process.env.REACT_APP_API_URL) {
        throw new Error('API_URL is not defined');
      }
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, apiKey: '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', target: 'both' })
      });
      setLoading(false);
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Es gab einen Fehler:', error);
    }
  };

  return (
    <div>
      {data.seoTitle === undefined && <p>Bitte gib eine URL ein. Mit http oder https!</p>}
      <input
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="URL"
      />

      <button onClick={handleSubmit}>Absenden</button>

      {loading ? <p>Spreche mit der KI...</p> : 
      <div className='ergebnis'>
        {data.seoTitle !== undefined &&
          <>
            <h2>Ergebnis:</h2>
            <dl>
            <dt>Title:</dt>
            <dd>{data.seoTitle}<button className="s copy" onClick={() => copyToClipboard(data.seoTitle as string)}>Titel kopieren</button></dd>
            <dt>Description:</dt>
            <dd>{data.seoDescription}<button className="s copy"  onClick={() => copyToClipboard(data.seoDescription as string)}>Description kopieren</button></dd>
            </dl>
          </>
        } 
      </div>
      }
      <button className="s" onClick={handleDelete}>API-Key entfernen</button>
    </div>
  );
};

export default ApiForm;
