// src/KeyForm.tsx

import React, { useState } from 'react';

interface KeyFormProps {
  callback: (apiKey: string) => void;
}


const KeyForm: React.FC<KeyFormProps> = ({ callback }) => {

  const [apiKey, setApikey] = useState('');

  const handleSubmit = async () => {
    localStorage.setItem('api_key', apiKey); // Speichert den apiKey im LocalStorage
    callback(apiKey);
  };

  return (
    <div>
      <p>Bitte gib deinen OpenAI-API-Key ein um den Generator nutzen zu können.</p>
      <input
        value={apiKey}
        onChange={(e) => setApikey(e.target.value)}
        placeholder="API-Key"
      />
      <button onClick={handleSubmit}>API-Key speichern</button>
      <p className="small"><small>Dein API-Key wird im lokalen Speicher deines Browsers gespeichert. Du kannst ihn nach dem Speichern wieder löschen.</small></p>
    </div>
  );
};

export default KeyForm;
